import React from "react"
import { Link, graphql } from "gatsby"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 1.45rem 1.0875rem;
`

const ArticleDate = styled.h5`
  display: inline;
  color: #606060;
  margin-bottom: 10px;
`

const MarkerHeader = styled.h3`
  display: inline;
  border-radius: 1em 0 1em 0;
  margin-bottom: 10px;
  background-image: linear-gradient(
    -100deg,
    rgba(255, 250, 150, 0.15),
    rgba(255, 250, 150, 0.8) 100%,
    rgba(255, 250, 150, 0.25)
  );
`
const IndexPage = ({ data }) => {
  console.log(data.allWordpressPost.edges[0])
  return (
    <Layout>
      <SEO title="Blog" />
      <Content>
        <h1>Older Posts</h1>
        {data.allWordpressPost.edges.map( post => (
          <div key={post.node.wordpress_id}> 
            <Link
              to={`/post/${post.node.slug}`}
              css={css`
                text-decoration: none;
                color: inherit;
              `}
            >
              <MarkerHeader dangerouslySetInnerHTML={{ __html: post.node.title }} />
              <div>
                <ArticleDate>{post.node.date}</ArticleDate>
              </div>
              <p dangerouslySetInnerHTML={{ __html: post.node.excerpt }} />
            </Link>
          </div>
        ))}
      </Content>
    </Layout>
  )
}

export default IndexPage

export const query = graphql `
  query {
      allWordpressPost (sort: {fields: date, order: DESC}, filter: {date: {lt: "2019"}}) {
        edges {
          node {
            title
            excerpt
            slug
            date(formatString: "MMMM DD, YYYY")
            wordpress_id
          }
        }
      }
    }
`
